





















































































































































































import { Component, Vue } from 'vue-property-decorator';

import ConfigureSecondContributionWealthViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/link-goal/configure-second-contribution-wealth-view-model';

@Component({
  name: 'ConfigureSecondContributionWealth',
  components: {},
})
export default class ConfigureSecondContributionWealth extends Vue {
  view_model = Vue.observable(
    new ConfigureSecondContributionWealthViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
