import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import { MonthlyCollectionDaysService } from '@/modules/my-investment/allianz/recurring-contributions/application/services';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import { StateManager }
  from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import {
  MonthlyCollectionDayOptionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/monthly-collection-day-option-entity';

type Distribution = {
  collection_day: number;
  amount_field: string;
  total_amount: number;
  new_amount: number;
  total_amount_filed: string;
  show_minimum_amount_error: boolean;
}

export default class ConfigureSecondContributionWealthViewModel {
  @Inject(TYPES.MONTHLY_COLLECTION_DAYS_SERVICE)
  readonly monthly_collection_days_service!: MonthlyCollectionDaysService;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  readonly recurring_contributions_state_manager!: StateManager;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-configure-second-contribution-wealth';

  readonly view!: Vue;

  recurring_contributions_state = this.recurring_contributions_state_manager.state;

  create_investor_goal_state = this.create_investor_goal_state_manager.state;

  second_reminder_day = '';

  minimum_amount = 500;

  step = 50;

  limit_per_contribution = 50000;

  invalid_distribution = false;

  monthly_collection_days: Array<MonthlyCollectionDayOptionEntity> = [];

  second_monthly_collection_days: Array<MonthlyCollectionDayOptionEntity> = [];

  distributions: Array<Distribution> = [];

  show_define_collection_day = false;

  disabled_first_day = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  get currency_minimum_amount() {
    return `${currencyFormat(this.minimum_amount)} MXN`;
  }

  get currency_total_goal_contribution() {
    return `${currencyFormat(this.create_investor_goal_state.total_goal_contribution)} MXN`;
  }

  get is_disabled() {
    return this.create_investor_goal_state.first_goal_contribution.collection_day === 0
      || this.create_investor_goal_state.second_goal_contribution.collection_day === 0
      || (this.invalid_distribution);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadMonthlyCollectionDays = () => {
    this.monthly_collection_days = this.monthly_collection_days_service.load();
  }

  loadAvailableDaysOnSecondCollectionDay = () => {
    const days = this.monthly_collection_days_service.load();
    this.second_monthly_collection_days = days.filter(
      (day) => this.monthly_collection_days_service
        .showDay(day, this.create_investor_goal_state.first_goal_contribution.collection_day),
    );
  }

  collectionDayChange = () => {
    this.loadAvailableDaysOnSecondCollectionDay();
  }

  appendDistribution = () => {
    this.distributions.push({
      collection_day: 0,
      total_amount: 0,
      amount_field: '0',
      new_amount: 0,
      total_amount_filed: `${currencyFormat(0)} MXN`,
      show_minimum_amount_error: false,
    });
  }

  assignBalanceNoContributionsLimitExceeded = (desired_amount: number) => {
    if (desired_amount > (this.limit_per_contribution + this.minimum_amount)) {
      const second_amount = desired_amount - this.limit_per_contribution;
      this.distributions[0].amount_field = this.limit_per_contribution.toString();
      this.changeAssignedAmountField(0);
      this.distributions[1].amount_field = second_amount.toString();
      this.changeAssignedAmountField(1);
    } else {
      const first_amount = desired_amount - this.minimum_amount;
      this.distributions[0].amount_field = first_amount.toString();
      this.changeAssignedAmountField(0);
      this.distributions[1].amount_field = this.minimum_amount.toString();
      this.changeAssignedAmountField(1);
    }
  }

  assignBalanceOneContributionLimitExceeded = (desired_amount: number) => {
    if (desired_amount < this.limit_per_contribution) {
      this.distributions[1].amount_field = desired_amount.toString();
      this.changeAssignedAmountField(1);
    } else {
      const unallocated_amount = desired_amount - this.limit_per_contribution;
      this.distributions[1].amount_field = this.limit_per_contribution.toString();
      this.changeAssignedAmountField(1);
      this.distributions[0].amount_field = unallocated_amount.toString();
      this.changeAssignedAmountField(0);
    }
  }

  assignBalanceFirstContributionOnLimit = (desired_amount: number) => {
    if (this.recurring_contributions_state.item.contributions[0]
      .amount === this.limit_per_contribution) {
      this.distributions[1].amount_field = desired_amount.toString();
      this.changeAssignedAmountField(1);
    }
    if (this.recurring_contributions_state.item.contributions[1]
      .amount === this.limit_per_contribution) {
      this.distributions[0].amount_field = desired_amount.toString();
      this.changeAssignedAmountField(0);
    }
  }

  loadDistributions = () => {
    this.appendDistribution();
    this.appendDistribution();
    const desired_amount = this.create_investor_goal_state.first_goal_contribution.amount;
    this.show_define_collection_day = this.recurring_contributions_state.item.contributions
      .length < 2;
    if (this.recurring_contributions_state.item.contributions.length > 0) {
      this.disabled_first_day = true;
      this.recurring_contributions_state.item.contributions.forEach((contribution, index) => {
        this.distributions[index].total_amount = contribution.amount;
        this.distributions[index].total_amount_filed = currencyFormat(contribution.amount);
        this.distributions[index].collection_day = contribution.collection_day;
      });
      if (this.recurring_contributions_state.item.contributions.length === 1) {
        this.assignBalanceOneContributionLimitExceeded(desired_amount);
      }
      if (this.recurring_contributions_state.item.contributions.length === 2) {
        this.assignBalanceFirstContributionOnLimit(desired_amount);
      }
      this.loadAvailableDaysOnSecondCollectionDay();
    } else if (desired_amount > this.limit_per_contribution) {
      this.assignBalanceNoContributionsLimitExceeded(desired_amount);
    }
  }

  validateTotalAmounts = () => {
    this.invalid_distribution = false;
    let total_amount = 0;
    this.distributions.forEach((distribution) => {
      total_amount += parseCurrencyToNumber(distribution.amount_field);
      if (!this.show_define_collection_day
        && parseCurrencyToNumber(distribution.total_amount_filed) < this.minimum_amount) {
        this.invalid_distribution = true;
      }
    });
    if (!this.invalid_distribution) {
      this.invalid_distribution = total_amount !== this.create_investor_goal_state
        .total_goal_contribution && !this.show_define_collection_day;
    }
  }

  updateOtherAmount = (index: number) => {
    const pending_amount = this.create_investor_goal_state.total_goal_contribution
      - parseCurrencyToNumber(this.distributions[index].amount_field);
    const other_index = index === 0 ? 1 : 0;
    if (this.distributions[other_index].total_amount < this.limit_per_contribution) {
      this.distributions[other_index].amount_field = pending_amount.toString();
      this.distributions[other_index].total_amount_filed = currencyFormat(
        (this.distributions[other_index].total_amount + pending_amount),
      );
    }
  }

  decrementAmount = (index: number) => {
    const current_amount = parseCurrencyToNumber(this.distributions[index].amount_field);
    const amount = current_amount - this.step;
    const new_amount = this.distributions[index].total_amount + amount;
    if (amount >= 0) {
      this.distributions[index].amount_field = amount.toString();
      this.distributions[index].new_amount = new_amount;
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount + amount),
      );
      this.distributions[index].show_minimum_amount_error = new_amount < this.minimum_amount;
      this.updateOtherAmount(index);
      this.validateTotalAmounts();
    }
  }

  incrementAmount = (index: number) => {
    const current_amount = parseCurrencyToNumber(this.distributions[index].amount_field);
    const amount = current_amount + this.step;
    const new_amount = this.distributions[index].total_amount + amount;
    if (amount > 0 && new_amount <= this.limit_per_contribution
      && amount <= this.create_investor_goal_state.total_goal_contribution) {
      this.distributions[index].amount_field = amount.toString();
      this.distributions[index].new_amount = new_amount;
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount + amount),
      );
      this.distributions[index].show_minimum_amount_error = new_amount < this.minimum_amount;
      this.updateOtherAmount(index);
      this.validateTotalAmounts();
    }
  }

  changeAssignedAmountField = (index: number) => {
    const current_amount = this.distributions[index].total_amount;
    const amount = parseCurrencyToNumber(this.distributions[index].amount_field);
    const new_amount = current_amount + amount;
    if (new_amount >= 0 && new_amount > this.limit_per_contribution) {
      this.distributions[index].amount_field = '0';
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount),
      );
      this.updateOtherAmount(index);
    } else {
      this.distributions[index].new_amount = new_amount;
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount + amount),
      );
      this.distributions[index].show_minimum_amount_error = (
        this.distributions[index].total_amount + new_amount
      ) < this.minimum_amount;
      this.updateOtherAmount(index);
    }
    this.validateTotalAmounts();
  }

  goToPrevStep = () => {
    if (this.recurring_contributions_state.item.contributions.length < 2
      && !this.show_define_collection_day) {
      this.show_define_collection_day = true;
    } else {
      this.view.$emit('prevStep');
    }
  }

  goToNextStep = () => {
    if (this.show_define_collection_day) {
      this.show_define_collection_day = false;
      this.distributions[0].collection_day = this.create_investor_goal_state
        .first_goal_contribution.collection_day;
      this.distributions[1].collection_day = this.create_investor_goal_state
        .second_goal_contribution.collection_day;
      this.validateTotalAmounts();
    } else {
      this.create_investor_goal_state.first_goal_contribution.set = true;
      this.create_investor_goal_state.second_goal_contribution.set = true;
      this.create_investor_goal_state.first_goal_contribution.amount = parseCurrencyToNumber(
        this.distributions[0].amount_field,
      );
      this.create_investor_goal_state.second_goal_contribution.amount = parseCurrencyToNumber(
        this.distributions[1].amount_field,
      );
      this.view.$emit('createAndLinkGoal');
    }
  }

  initialize = () => {
    this.loadMonthlyCollectionDays();
    this.loadDistributions();
    this.validateTotalAmounts();
    this.second_reminder_day = this.translate('recurring_not_defined');
  }
}
